import Vue from 'vue'
import Vuetable from 'vuetable-2'
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetableFieldCheckbox from 'vuetable-2/src/components/VuetableFieldCheckbox.vue';
import VuetableFieldMixin from 'vuetable-2/src/components/VuetableFieldMixin'
import $ from 'jquery'
import axios from "axios";
import JsonExcel from 'vue-json-excel'
import moment from "moment";
Vue.component('downloadExcel', JsonExcel)
import vSelect from 'vue-select'


export default {
    components: {
        Vuetable,
        VuetablePagination,
        VuetableFieldCheckbox,
        VuetableFieldMixin,
        vSelect
    },
    data: function() {
        return {
            pageTitle: this.$t('stock_en_valeur.page_titre'),
            point_de_ventes_id: "",
            point_de_ventes: "",
            filtreDate: {
                dateDebut: "",
                dateFin: ""
            },
            montant_total: "0",
            listeTypeDepense: [],
            vuetable: {
                moreParams: {},
                fields: [{
                        name: 'date_formatted',
                        title: this.$t('stock_en_valeur.date'),
                        sortField: 'date',
                        dataClass: 'text-left'
                    },
                    {
                        name: 'commentaires',
                        title: this.$t('stock_en_valeur.commentaire'),
                        sortField: 'commentaire',
                        dataClass: 'text-left',
                        titleClass: "text-left",
                    },
                    {
                        name: 'valeur',
                        title: this.$t('stock_en_valeur.valeur_total'),
                        sortField: 'valeur',
                        dataClass: 'text-right',
                        titleClass: "text-center",
                    },
                    {
                        name: 'date_heure_enregistrement',
                        title: this.$t('stock_en_valeur.date_enr'),
                        dataClass: 'text-center',
                        titleClass: 'text-center',
                        sortField: 'date_heure_enregistrement',
                        width: "170px",
                    },

                ],
                sortOrder: [
                    { field: 'date', direction: 'desc' }
                ],
                css: {
                    table: {
                        tableClass: 'table table-striped table-bordered table-hovered',
                        loadingClass: 'loading',
                        ascendingIcon: 'fas fa-arrow-up fa-sm',
                        descendingIcon: 'fas fa-arrow-down fa-sm',
                        handleIcon: 'fas fa-bars',
                    },
                    pagination: {
                        infoClass: 'pull-left ',
                        wrapperClass: 'vuetable-pagination text-center',
                        activeClass: 'btn-secondary',
                        disabledClass: 'disabled',
                        pageClass: 'btn btn-border',
                        linkClass: 'btn btn-border',
                        icons: {
                            first: '',
                            prev: '',
                            next: '',
                            last: '',
                        },
                    }
                },
            },
            exportexcel: {
                json_fields: {
                    'Date': 'date_formatted',
                    'Commentaires': 'commentaires',
                    'Valeur total': 'valeur',
                    'Date enr.': 'date_heure_enregistrement'
                },
                json_data: [],
                json_meta: [
                    [{
                        'key': 'charset',
                        'value': 'utf-8'
                    }]
                ],
            },
            motCle: "",
            criteriacol: "",
            crudform: {
                id: "",
                date: "",
                type_depense: "",
                beneficiaire: "",
                montant: "",
                motif: "",
                date_enregistrement: ""
            },
            crudmodaltitle: "Ajouter une dépense",
            dateOptions: {
                format: 'DD/MM/YYYY',
            },
            // Vuetable Detais params
            vuetable_detail: {
                moreParams: {},
                fields: [{
                        name: 'nom_magasin',
                        title: this.$t('stock_en_valeur.magasin'),
                        sortField: 'nom_magasin',
                        dataClass: 'text-left'
                    },
                    {
                        name: 'nom_famille',
                        title: this.$t('stock_en_valeur.famille'),
                        sortField: 'nom_famille',
                        dataClass: 'text-left'
                    },
                    {
                        name: 'ref_article',
                        title: this.$t('stock_en_valeur.reference'),
                        sortField: 'ref_article',
                        dataClass: 'text-left'
                    },
                    {
                        name: 'designation',
                        title: this.$t('stock_en_valeur.designation'),
                        sortField: 'designation',
                        dataClass: 'text-left',
                        titleClass: "text-left",
                    },
                    {
                        name: 'prix_revient',
                        title: this.$t('stock_en_valeur.prix_revient'),
                        sortField: 'prix_revient',
                        dataClass: 'text-right',
                        titleClass: "text-right",
                    },
                    {
                        name: 'pu_ht',
                        title: this.$t('stock_en_valeur.pu_vente_ht'),
                        sortField: 'pu_ht',
                        dataClass: 'text-right',
                        titleClass: "text-right",
                    },
                    {
                        name: 'tva',
                        title: this.$t('stock_en_valeur.tva'),
                        sortField: 'tva',
                        dataClass: 'text-right',
                        titleClass: "text-right",
                        width: "50px"
                    },
                    {
                        name: 'pu_ttc',
                        title: this.$t('stock_en_valeur.pu_vente_ttc'),
                        sortField: 'pu_ttc',
                        dataClass: 'text-right',
                        titleClass: "text-right",
                    },
                    {
                        name: 'qte_stock',
                        title: this.$t('stock_en_valeur.quantite_stock'),
                        sortField: 'qte_stock',
                        dataClass: 'text-right',
                        titleClass: "text-right",
                    },
                ],
                sortOrder: [
                    { field: 'designation', direction: 'asc' }
                ],
                css: {
                    table: {
                        tableClass: 'table table-striped table-bordered table-hovered',
                        loadingClass: 'loading',
                        ascendingIcon: 'fas fa-arrow-up fa-sm',
                        descendingIcon: 'fas fa-arrow-down fa-sm',
                        handleIcon: 'fas fa-bars',
                    },
                    pagination: {
                        infoClass: 'pull-left ',
                        wrapperClass: 'vuetable-pagination text-center',
                        activeClass: 'btn-secondary',
                        disabledClass: 'disabled',
                        pageClass: 'btn btn-border',
                        linkClass: 'btn btn-border',
                        icons: {
                            first: '',
                            prev: '',
                            next: '',
                            last: '',
                        },
                    }
                },
            },
            selectedRow: {
                data: {},
                index: 0
            },
            firstload: true,



        }
    },
    methods: {
        onPaginationData(paginationData) {
            this.$refs.pagination.setPaginationData(paginationData);
            console.log(paginationData);
        },
        onPaginationDataDetail(paginationData) {
            this.$refs.pagination_detail.setPaginationData(paginationData);
            console.log(paginationData);
        },
        onChangePage(page) {
            this.$refs.vuetable.changePage(page)
        },
        onChangePageDetail(page) {
            this.$refs.vuetable_detail.changePage(page)
        },
        onLoading() {
            console.log('loading... show your spinner here')
        },
        onLoaded() {
            console.log('loaded! .. hide your spinner here');
            if (this.firstload && this.$refs.vuetable.$data.tableData.length > 0) {
                this.selectRow(this.$refs.vuetable.$data.tableData[0], 0);
            }
            this.firstload = false;
        },
        onLoadingDetail() {
            console.log('loading... show your spinner here')
        },
        onLoadedDetail() {
            console.log('loaded! .. hide your spinner here');
        },
        setFilter() {
            this.vuetable.moreParams.filter = this.motCle;
            this.vuetable.moreParams.dateDebut = this.filtreDate.dateDebut;
            this.vuetable.moreParams.dateFin = this.filtreDate.dateFin;
            this.vuetable.moreParams.point_de_ventes_id = this.point_de_ventes_id;
            Vue.nextTick(() => this.$refs.vuetable.refresh());

            this.vuetable_detail.moreParams.stock_en_valeur_id = "";
            this.vuetable_detail.moreParams.point_de_ventes_id = "";
            Vue.nextTick(() => this.$refs.vuetable_detail.refresh());
        },
        resetFilter() {
            this.motCle = "";
            this.criteriacol = "";
            this.point_de_ventes_id = "";
            this.vuetable.moreParams.filter = this.motCle;
            this.vuetable.moreParams.dateDebut = this.filtreDate.dateDebut;
            this.vuetable.moreParams.dateFin = this.filtreDate.dateFin;
            this.vuetable_detail.moreParams.point_de_ventes_id = this.point_de_ventes_id;
            Vue.nextTick(() => this.$refs.vuetable.refresh());
        },
        onExport() {

            this.exportexcel.json_data = this.$refs.vuetable.$data.tableData;
            $("#excel-download").trigger("click");
        },
        openModal() {
            this.$bvModal.show("crudmodal");
        },
        closeModal() {
            this.$bvModal.hide("crudmodal");
        },
        loadPointDeVente() {
            axios.get(this.BASE_URL + "/pointvente/getall/").then(response => {
                //console.log(response.data[0]);
                this.point_de_ventes = response.data;
            });
        },
        selectRow: function(rowData, index) {
            this.selectedRow.index = index;
            this.selectedRow.data = rowData;

        },
        onRowClicked: function(e) {
            this.selectRow(e.data, e.index);

        },
        onRowClass: function(dataItem) {
            if (dataItem.id == this.selectedRow.data.id)
                return 'selected-row';
            return '';
        }
    },
    computed: {
        /*httpOptions(){
            return {headers: {'Authorization': "my-token"}} //table props -> :http-options="httpOptions"
        },*/
    },
    created: function() {
        this.$emit('change-page', this.pageTitle);

        var currentDateWithFormat = moment().format('DD/MM/YYYY');
        this.filtreDate.dateDebut = currentDateWithFormat;
        this.filtreDate.dateFin = currentDateWithFormat;
        this.setFilter();

        this.loadPointDeVente();
    },
    watch: {
        'selectedRow.data': function() {
            // console.log(this.selectedRow.data)
            if (this.selectedRow.data != null && this.selectedRow.data != undefined && this.selectedRow.data != {}) {
                this.vuetable_detail.moreParams.stock_en_valeur_id = this.selectedRow.data.id;

            } else {
                this.vuetable_detail.moreParams.stock_en_valeur_id = "";
            }

            this.vuetable_detail.moreParams.point_de_ventes_id = this.point_de_ventes_id;
            Vue.nextTick(() => this.$refs.vuetable_detail.refresh());
        },
        'point_de_ventes_id': function() {
            this.firstload = true;
        }
    },
    mounted: function() {

    }
}